import ReactMaterialComponentBase from "../../Shared/ReactMaterialComponentBase"
import { CreateRecipe as ReactCreateRecipe } from "../React/CreateRecipe"
import React from "react"

export default class EditRecipe extends ReactMaterialComponentBase {
  static get observedAttributes() {
    return ["target", "book-target"]
  }

  constructor() {
    super()

    this.jsxRootComponent = () => (
      <ReactCreateRecipe recipe={this.recipe} bookUrl={this.bookUrl} createRecipe={(recipe) => this.update(recipe)} />
    )
  }

  /**
   * @param {import("../Types").Recipe} recipe
   */
  async update(recipe) {
    try {
      const token = await window.authorise()

      const updatedRecipe = await fetch(`${this.getAttribute("target")}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
        body: JSON.stringify(recipe),
      })

      if (!updatedRecipe.ok) {
        throw Error("Failed to update recipe")
      }

      this.onSuccess && this.onSuccess("Recipe updated")
    } catch (error) {
      this.onError && this.onError("Failed to update recipe")
    }
  }

  async attributeChangedCallback(name, oldValue, newValue) {
    if (name === "book-target") {
      this.bookUrl = newValue

      this.render()
    }

    if (name === "target") {
      const token = await window.authorise()

      const recipeResponse = await fetch(`${this.getAttribute("target")}`, {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${token}` },
      })

      if (!recipeResponse.ok) {
        throw Error("Failed to get recipe")
      }

      this.recipe = await recipeResponse.json()

      this.render()
    }
  }
}

customElements.define("edit-recipe", EditRecipe)
